import React from "react";

// Style
import styled from "styled-components";
import { motion } from "framer-motion";

// Icons
// import Icon from "assets/icons/how-it-works/icon-title.inline.svg"

// Utils
import breakpoints from "utils/breakpoints";
import { colors } from "utils/variables";

// Components
import Container from "components/container";
import Card from "components/how-it-works--card";
import Separator from "components/separator";

const StyledHIW = styled.section`
  background-color: ${colors.gris};
  text-align: center;
  color: ${colors.azul};
  padding-top: 139px;
  margin-bottom: -160px;
  z-index: 9998;

  ${breakpoints.medium`
    margin-bottom: -70px;
    margin-top: -57px;
  `}

  .hiw__title {
    margin-bottom: 16px;
    margin-top: 16px;
  }

  .hiw__subtitle {
    margin-bottom: 54px;
  }

  .row {
    .card {
      margin-bottom: 32px;

      &:last-of-type {
        margin-bottom: 0;
      }

      ${breakpoints.small`
        margin-bottom: 0px;
        &:last-of-type {
          margin-bottom: 0px;
        }
      `}
    }
  }
`;

const HowItWorks = () => {
  const howItWorks = [
    {
      number: "1",
      title: "Ecografía",
      description:
        "Para confirmar la vitalidad fetal antes de la toma de muestras.",
      footer: "*Consulta ciudades con ecografía disponible",
    },
    {
      number: "2",
      title: "Toma de muestras",
      description: "Una simple extracción de sangre del brazo.",
    },
    {
      number: "3",
      title: "Entrega de resultado",
      description: "Te contactamos para explicarte el resultado con detalle.",
    },
    {
      number: "4",
      title: "Seguimiento",
      description:
        "Ante un resultado positivo, te guiamos en los próximos pasos y cubrimos el estudio confirmatorio sin costo.",
      footer: "*Consulta restricciones",
    },
  ];
  return (
    <StyledHIW id="como-se-hace">
      <Container data-parallax="vertical">
        <Separator width="40px" colors={[colors.magenta, colors.azul]} />
        <h3 className="hiw__title">¿Cómo se hace?</h3>
        <p className="hiw__subtitle paragraph--medium">
          Te guiaremos desde la primera llamada, con un equipo médico a tu
          disposición.
        </p>
        <div className="row">
          {howItWorks.map((data, index) => (
            <motion.div
              key={data.title}
              className="card col-12 col-sm-6 col-md-3"
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{
                delay: index * 0.1,
              }}
              variants={{
                visible: {
                  y: 0,
                  opacity: 1,
                  scale: 1,
                },
                hidden: {
                  y: 64,
                  opacity: 0,
                  scale: 0.9,
                },
              }}
            >
              <Card
                number={data.number}
                title={data.title}
                description={data.description}
                footer={data.footer}
              />
            </motion.div>
          ))}
        </div>
      </Container>
    </StyledHIW>
  );
};

export default HowItWorks;
