import React from "react";

// Sections
import Hero from "sections/home/hero";
import Trayectoria from "sections/home/trayectoria";
import HowItWorks from "sections/home/how-it-works";
import Cta from "sections/home/call-to-action";
import Options from "sections/home/options";
import QueEs from "sections/home/que-es";
import Testimonials from "sections/home/testimonials";
import Contact from "sections/home/contact";
import Faq from "sections/home/faq";
import Features from "sections/home/features";

const Home = () => (
  <>
    <Hero />
    <div className="gradient--blue">
      <Features />
      <Testimonials />
    </div>
    <QueEs />
    <HowItWorks />
    <Cta />
    <Options />
    <Trayectoria />
    <Contact />
    <Faq />
  </>
);

export default Home;
