import React, { useState } from "react";

// Libraries
import styled from "styled-components";
import { transparentize } from "polished";

// Utils
import { colors, gradients } from "utils/variables/";
import breakpoint from "utils/breakpoints/";

// Components
import Container from "components/container/";
import Form from "components/form/";
import { PrimaryButton, SecondaryButton } from "components/button";

// Icons
import IconCheck from "assets/icons/icon-check.inline.svg";
import IconClose from "assets/icons/icon-close.inline.svg";
import IconTitle from "assets/icons/contacto/icon-title.inline.svg";
import Background from "assets/icons/contacto/contact-background.svg";

const data = require("../../assets/data/data.json");

const StyledContact = styled.section`
  padding: 80px 0;
  background: url("${Background}"), ${gradients.blue};
  background-size: 100% auto;
  background-position: bottom;
  background-repeat: no-repeat;

  ${breakpoint.medium`
    padding: 170px 0 150px 0;
  `}

  .contact__content {
    margin-bottom: 30px !important;
    ${breakpoint.medium`
      text-align: left !important;
    `}

    .title {
      margin-top: 15px;
      margin-bottom: 16px;

      ${breakpoint.medium`
        margin-top: 23px;
      `}
    }

    .paragraph--small {
      max-width: 357px;
    }
  }

  .contact__form {
    position: relative;

    ${breakpoint.medium`
      margin-top: 46px;
    
    `}

    .form {
      opacity: ${(props) => (props.showSuccess || props.showError ? "0" : "1")};
      transition: all 0.3s ease;

      input::placeholder {
        color: ${colors.gris__2};
      }
    }

    .button__send-contulta {
      width: 100%;
      text-align: center;

      ${breakpoint.medium`
        width: auto;
      
      `}
    }
  }

  .contact__form-message {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;

    .content {
      width: 100%;
      aspect-ratio: 1;
      padding: 48px 24px;
      border: 3px solid ${transparentize(0.7, colors.blanco)};
      background-color: ${transparentize(0.9, colors.blanco)};
      backdrop-filter: blur(20px);
      text-align: center;

      ${breakpoint.medium`
        padding-top: 110px 58px;
      `}

      svg {
        width: 48px;
        height: 48px;
      }
    }
  }
`;

const Contact = () => {
  const [formFields, setFormFields] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [formValidation, setFormValidation] = useState({
    name: true,
    email: true,
    message: true,
    phone: true,
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleInputChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setFormFields({
      ...formFields,
      [name]: value,
    });

    if (formValidation[name] !== "undefined" && !formValidation[name]) {
      setFormValidation({
        ...formValidation,
        [name]: true,
      });
    }
  };

  const handleFormValidation = () => {
    const _formValidation = formValidation;

    Object.keys(_formValidation).forEach((key) => {
      _formValidation[key] = formFields[key] !== "";
    });

    setFormValidation({
      ...formValidation,
      ..._formValidation,
    });
  };

  const formIsValid = () => !Object.values(formValidation).includes(false);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validates the form
    handleFormValidation();

    if (formIsValid()) {
      await fetch("https://api.genesia.la/", {
        method: "post",
        body: JSON.stringify({
          action: "form_submit",
          amoCRMCountry: data.amoCRMCountry,
          amoCRMUser: data.amoCRMUser,
          adCampaignType: "TBD",
          to: data.to,
          ...formFields,
        }),
      }).then((response) => {
        if (response.status === 200) {
          setShowSuccess(true);
        } else {
          setShowError(true);
        }
      });
    }
  };

  return (
    <StyledContact
      showSuccess={showSuccess}
      showError={showError}
      className="color--blanco"
      id="contact"
    >
      <Container small data-parallax="vertical">
        <div className="row justify-content-between">
          <div className="contact__content col-12 col-md-5 mb-4 text-center">
            <IconTitle />
            <h3 className="title">Contactanos</h3>

            <p className="paragraph--medium mb-5 font-weight--500">
              Despeja todas tus dudas con expertos en genética, sin ningún
              compromiso.
            </p>

            {data?.addresses?.length > 1 && (
              <p className="paragraph--medium mb-4 font-weight--600">
                Oficinas
              </p>
            )}

            {data?.addresses?.map((address, index) => (
              <p
                className="paragraph--small mb-4 font-weight--500"
                key={address.title}
              >
                <b className="color--rosa font-weight--600">{address.title}</b>
                {address.phoneNumber && (
                  <>
                    <br />
                    {address.phoneNumber}
                  </>
                )}
                {address.street && (
                  <>
                    <br />
                    {address.street}
                  </>
                )}
                {address.city && (
                  <>
                    <br />
                    {address.city}
                  </>
                )}
                {address.zipcode && (
                  <>
                    <br />
                    {address.zipcode}
                  </>
                )}
                {address.description && (
                  <>
                    <br />
                    {address.description}
                  </>
                )}
              </p>
            ))}

            {/* <p className="paragraph--small font-weight--500">
              <b className="color--rosa font-weight--600">
                Centros de recolección
              </b>
              <br />
              Contamos con más de 40 centros de recolección distribuidos en el
              país.
            </p> */}
          </div>

          <div className="contact__form col-12 col-md-6">
            <Form className="form">
              <div className="form__group">
                <label htmlFor="name">
                  Nombre
                  <input
                    type="text"
                    className="mt-3 color--gris__2"
                    id="name"
                    name="name"
                    placeholder="Tu nombre"
                    onChange={handleInputChange}
                  />
                </label>

                {!formValidation.name && (
                  <small className="form__message color--magenta">
                    Por favor ingresá tu nombre
                  </small>
                )}
              </div>

              <div className="form__group">
                <label htmlFor="phone">
                  WhatsApp / Teléfono
                  <input
                    type="text"
                    className="mt-3"
                    id="phone"
                    name="phone"
                    placeholder="Ej. 11 4567763"
                    onChange={handleInputChange}
                  />
                </label>

                {!formValidation.phone && (
                  <small className="form__message color--magenta">
                    Por favor ingresá tu WhatsApp / teléfono
                  </small>
                )}
              </div>

              <div className="form__group">
                <label htmlFor="email">
                  Email
                  <input
                    type="email"
                    className="mt-3"
                    id="email"
                    name="email"
                    placeholder="Tu email"
                    onChange={handleInputChange}
                  />
                </label>

                {!formValidation.email && (
                  <small className="form__message color--magenta">
                    Por favor ingresá tu email
                  </small>
                )}
              </div>

              <div className="form__group">
                <label htmlFor="message">
                  Mensaje
                  <input
                    type="text"
                    className="mt-3"
                    id="message"
                    name="message"
                    placeholder="Escribe tu consulta aquí"
                    onChange={handleInputChange}
                  />
                </label>

                {!formValidation.message && (
                  <small className="form__message color--magenta">
                    Por favor ingresá tu mensaje
                  </small>
                )}
              </div>

              <div className="button d-flex justify-content-end">
                <PrimaryButton
                  type="button"
                  className="button__send-contulta bg--magenta bg-hover--rosa"
                  onClick={handleSubmit}
                >
                  Enviar consulta
                </PrimaryButton>
              </div>
            </Form>

            {showSuccess && (
              <div className="contact__form-message">
                <div className="content d-flex align-items-center justify-content-center">
                  <div>
                    <IconCheck className="mb-4" />

                    <h4 className="mb-2 font-weight--700">
                      Tu mensaje fue enviado con éxito.
                    </h4>

                    <p className="paragraph--small">
                      Nuestro equipo se comunicará contigo lo antes posible.
                      <br />
                      ¡Gracias por comunicarte!
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3 mt-md-4">
                  <SecondaryButton
                    type="button"
                    className="border--blanco border-hover--rosa color--blanco"
                    onClick={() => setShowSuccess(false)}
                  >
                    Enviar otra consulta
                  </SecondaryButton>
                </div>
              </div>
            )}

            {showError && (
              <div className="contact__form-message">
                <div className="content d-flex align-items-center justify-content-center">
                  <div>
                    <IconClose className="mb-4" />

                    <h4 className="mb-2 font-weight--700">Algo salió mal.</h4>

                    <p className="paragraph--small">
                      Por favor, intenta nuevamente.
                    </p>
                  </div>
                </div>

                <div className="d-flex justify-content-end mt-3 mt-md-4">
                  <SecondaryButton
                    type="button"
                    className="border--blanco border-hover--rosa color--blanco"
                    onClick={() => setShowError(false)}
                  >
                    Intentar de nuevo
                  </SecondaryButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </Container>
    </StyledContact>
  );
};

export default Contact;
